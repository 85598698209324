import 'bsky-embed/dist/bsky-embed.es.js'

type BlueskyEmbedProps = {
  username?: string
  feed?: string
  search?: string
  mode?: 'dark' | '' // Specify the modes explicitly
  limit?: string | number
  linkTarget?: '_self' | '_blank' | '_parent' | '_top'
  linkImage?: boolean
  loadMore?: boolean
  customStyles?: string
}

export default function BlueskyEmbed(props: BlueskyEmbedProps) {
  const { username, limit = 10, loadMore = false } = props

  return (
    <bsky-embed
      username={username}
      limit={limit}
      load-more="false"
    ></bsky-embed>
  )
}
